.ScrollbarStyle {    
    overflow: auto;
    margin: 4px;
    border: none;
  }
  .ScrollbarStyle-1::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .ScrollbarStyle-1::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background: #2f2f2f;
  }
  .ScrollbarStyle-1::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    background: #1f1f1f;
  }